import { FunctionComponent, SVGProps } from "react";

export const TwitterIcon: FunctionComponent<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#213B51" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M19.9525 7.91998C19.9647 8.0963 19.9647 8.27261 19.9647 8.45055C19.9647 13.8724 15.8371 20.1255 8.28966 20.1255V20.1223C6.06013 20.1255 3.8769 19.4869 2 18.2828C2.32419 18.3218 2.65001 18.3413 2.97664 18.3421C4.82429 18.3437 6.61913 17.7238 8.07272 16.5822C6.31688 16.5489 4.77717 15.404 4.23928 13.7327C4.85436 13.8513 5.48812 13.8269 6.09181 13.662C4.17753 13.2753 2.80033 11.5934 2.80033 9.64007C2.80033 9.6222 2.80033 9.60513 2.80033 9.58807C3.37071 9.90576 4.00934 10.0821 4.6626 10.1016C2.85964 8.89662 2.30388 6.49808 3.39265 4.6228C5.47593 7.18628 8.54967 8.74468 11.8493 8.90962C11.5186 7.48447 11.9703 5.99108 13.0364 4.98925C14.689 3.43572 17.2882 3.51535 18.8418 5.16719C19.7607 4.986 20.6415 4.64881 21.4475 4.17105C21.1412 5.12088 20.5001 5.9277 19.6437 6.4404C20.457 6.34452 21.2517 6.12677 22 5.79445C21.4491 6.61996 20.7552 7.33903 19.9525 7.91998Z" />
    </svg>
  );
};

export const DiscordIcon: FunctionComponent<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#213B51" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M18.9308 5.64136C17.6561 5.05646 16.2892 4.62553 14.8599 4.37872C14.8339 4.37396 14.8079 4.38586 14.7945 4.40967C14.6187 4.72235 14.4239 5.13026 14.2876 5.45088C12.7503 5.22074 11.221 5.22074 9.71527 5.45088C9.57887 5.12314 9.37707 4.72235 9.20048 4.40967C9.18707 4.38666 9.16107 4.37475 9.13504 4.37872C7.70659 4.62474 6.33963 5.05567 5.06411 5.64136C5.05307 5.64612 5.04361 5.65407 5.03732 5.66438C2.44449 9.53801 1.73421 13.3164 2.08265 17.048C2.08423 17.0663 2.09447 17.0837 2.10867 17.0948C3.81934 18.3511 5.47642 19.1138 7.10273 19.6193C7.12876 19.6272 7.15634 19.6177 7.1729 19.5963C7.55761 19.0709 7.90054 18.517 8.19456 17.9345C8.21192 17.9003 8.19535 17.8599 8.15989 17.8464C7.61594 17.64 7.098 17.3885 6.59977 17.1028C6.56037 17.0798 6.55721 17.0234 6.59347 16.9964C6.69831 16.9178 6.80318 16.8361 6.9033 16.7536C6.92141 16.7385 6.94665 16.7353 6.96794 16.7448C10.2411 18.2392 13.7846 18.2392 17.0191 16.7448C17.0404 16.7345 17.0657 16.7377 17.0846 16.7528C17.1847 16.8353 17.2895 16.9178 17.3952 16.9964C17.4314 17.0234 17.4291 17.0798 17.3897 17.1028C16.8914 17.394 16.3735 17.64 15.8288 17.8456C15.7933 17.8591 15.7775 17.9003 15.7949 17.9345C16.0952 18.5162 16.4381 19.0701 16.8157 19.5955C16.8315 19.6177 16.8599 19.6272 16.8859 19.6193C18.5201 19.1138 20.1772 18.3511 21.8879 17.0948C21.9028 17.0837 21.9123 17.0671 21.9139 17.0488C22.3309 12.7347 21.2154 8.98725 18.9568 5.66517C18.9513 5.65407 18.9419 5.64612 18.9308 5.64136ZM8.68335 14.7759C7.69792 14.7759 6.88594 13.8712 6.88594 12.7601C6.88594 11.649 7.68217 10.7443 8.68335 10.7443C9.69239 10.7443 10.4965 11.657 10.4807 12.7601C10.4807 13.8712 9.68451 14.7759 8.68335 14.7759ZM15.329 14.7759C14.3435 14.7759 13.5316 13.8712 13.5316 12.7601C13.5316 11.649 14.3278 10.7443 15.329 10.7443C16.338 10.7443 17.1421 11.657 17.1264 12.7601C17.1264 13.8712 16.338 14.7759 15.329 14.7759Z" />
    </svg>
  );
};

export const GithubIcon: FunctionComponent<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#213B51" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 2C5.70144 2 1 6.70144 1 12.5C1 17.1388 4.00795 21.0753 8.18082 22.4627C8.70591 22.5601 8.89891 22.235 8.89891 21.9568C8.89891 21.7064 8.88847 20.8788 8.885 20.0025C5.96225 20.6371 5.34675 18.7645 5.34675 18.7645C4.8686 17.5509 4.18182 17.2275 4.18182 17.2275C3.22901 16.5755 4.2531 16.5894 4.2531 16.5894C5.30676 16.6642 5.86314 17.6709 5.86314 17.6709C6.8003 19.2757 8.31992 18.8115 8.91803 18.5437C9.01192 17.8656 9.2849 17.4014 9.58395 17.1388C7.25236 16.8728 4.8008 15.9722 4.8008 11.9506C4.8008 10.8048 5.21113 9.86761 5.88227 9.13214C5.77273 8.86786 5.41456 7.8003 5.98311 6.3537C5.98311 6.3537 6.86463 6.07203 8.87109 7.42996C9.70914 7.19697 10.6063 7.08048 11.5 7.077C12.392 7.08048 13.2909 7.19697 14.1307 7.42996C16.1354 6.07203 17.0152 6.3537 17.0152 6.3537C17.5872 7.8003 17.2273 8.86786 17.1177 9.13214C17.7906 9.86761 18.1975 10.8048 18.1975 11.9506C18.1975 15.9826 15.7407 16.8711 13.4039 17.1319C13.7812 17.457 14.1167 18.0969 14.1167 19.0758C14.1167 20.4806 14.1046 21.6108 14.1046 21.9568C14.1046 22.2367 14.2941 22.5636 14.8261 22.461C18.9955 21.0718 22 17.1371 22 12.5C22 6.70144 17.2986 2 11.5 2Z"
      />
      <path d="M4.97647 17.0745C4.95387 17.1267 4.87041 17.1423 4.79565 17.1058C4.71915 17.071 4.67742 16.9997 4.70176 16.9476C4.72436 16.8937 4.80608 16.8798 4.88258 16.9146C4.95909 16.9511 5.00255 17.0224 4.97647 17.0745Z" />
      <path d="M5.40243 17.5491C5.35201 17.5961 5.25465 17.5735 5.18857 17.5004C5.11903 17.4274 5.10686 17.33 5.15728 17.2831C5.20944 17.2362 5.30333 17.2588 5.37288 17.3318C5.44069 17.4048 5.4546 17.5022 5.40243 17.5491Z" />
      <path d="M5.81621 18.1543C5.75187 18.1995 5.64581 18.1578 5.58148 18.0639C5.51715 17.97 5.51715 17.8588 5.58322 17.8135C5.64755 17.7683 5.75187 17.8083 5.81794 17.9022C5.88228 17.9961 5.88228 18.1091 5.81621 18.1543Z" />
      <path d="M6.3829 18.7383C6.32552 18.8027 6.20207 18.7853 6.1134 18.6983C6.02125 18.6131 5.99691 18.4932 6.05428 18.4306C6.1134 18.3663 6.23511 18.3854 6.32552 18.4706C6.41593 18.554 6.44375 18.6757 6.3829 18.7383Z" />
      <path d="M7.16544 19.0776C7.13936 19.1593 7.02112 19.1976 6.90289 19.1628C6.78466 19.1263 6.70642 19.0306 6.73076 18.9472C6.7551 18.8637 6.87333 18.8255 6.9933 18.862C7.11328 18.8985 7.18978 18.9941 7.16544 19.0776Z" />
      <path d="M8.0243 19.1401C8.02777 19.227 7.92693 19.2983 7.80174 19.3001C7.67656 19.3035 7.57397 19.2322 7.57397 19.1471C7.57397 19.0601 7.67308 18.9888 7.79827 18.9871C7.92171 18.9854 8.0243 19.0549 8.0243 19.1401Z" />
      <path d="M8.82413 19.0046C8.83977 19.0898 8.75284 19.1749 8.62765 19.1993C8.50594 19.2219 8.39293 19.1697 8.37728 19.0863C8.36163 18.9993 8.45031 18.9124 8.57201 18.8898C8.6972 18.8672 8.80848 18.9194 8.82413 19.0046Z" />
    </svg>
  );
};

export const GitbookIcon: FunctionComponent<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="#213B51" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_46_61)">
        <path d="M10.7169 14.5999C11.0888 14.5999 11.4234 14.8974 11.4234 15.3063C11.4234 15.6782 11.126 16.0129 10.7169 16.0129C10.3451 16.0129 10.0104 15.7154 10.0104 15.3063C10.0104 14.8974 10.3451 14.5999 10.7169 14.5999ZM21.6489 10.2866C21.277 10.2866 20.9424 9.98915 20.9424 9.58013C20.9424 9.2083 21.2398 8.87364 21.6489 8.87364C22.0207 8.87364 22.3554 9.17111 22.3554 9.58013C22.3554 9.95197 22.0207 10.2866 21.6489 10.2866ZM21.6489 7.42349C20.459 7.42349 19.4922 8.39026 19.4922 9.58013C19.4922 9.80324 19.5293 10.0263 19.6037 10.2494L12.5017 14.0421C12.0927 13.4472 11.4234 13.1126 10.7169 13.1126C9.89887 13.1126 9.1552 13.5959 8.78337 14.3024L2.38781 10.9559C1.7185 10.5841 1.19794 9.50576 1.27231 8.46463C1.30949 7.94406 1.49541 7.53504 1.75569 7.3863C1.9416 7.27475 2.12752 7.31194 2.38781 7.42349L2.42499 7.46067C4.13543 8.35308 9.67577 11.2534 9.89887 11.3649C10.2707 11.5137 10.4566 11.5881 11.0888 11.2906L22.5412 5.34122C22.7272 5.26685 22.9131 5.11812 22.9131 4.85783C22.9131 4.52318 22.5784 4.37444 22.5784 4.37444C21.9091 4.07698 20.9052 3.59359 19.9384 3.14739C17.8561 2.18062 15.4764 1.06512 14.4353 0.507364C13.5428 0.0239781 12.7992 0.432996 12.6876 0.507364L12.4273 0.618914C7.70504 2.99865 1.45823 6.08488 1.08639 6.30798C0.454269 6.67982 0.045251 7.46067 0.00806751 8.42745C-0.0662995 9.95197 0.714553 11.5509 1.83005 12.1086L8.59745 15.6039C8.74619 16.645 9.67577 17.4631 10.7169 17.4631C11.9068 17.4631 12.8364 16.5334 12.8736 15.3435L20.3102 11.3277C20.682 11.6253 21.1655 11.7739 21.6489 11.7739C22.8387 11.7739 23.8055 10.8072 23.8055 9.61732C23.8055 8.39026 22.8387 7.42349 21.6489 7.42349Z" />
      </g>
      <defs>
        <clipPath id="clip0_46_61">
          <rect width="23.9937" height="17.45" fill="white" transform="translate(0.00317383 0.275024)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const UnionIcon: FunctionComponent<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="#213B51" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_46_58)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1179 8.02855L18 11.0615L13.2953 15.9999L12.0893 14.7692L15.6179 11.0615L13.9653 9.32301L9.85644 13.6199V15.8768H8.23361V13.6626L4.03472 9.24609L2.397 10.9692L5.9702 14.7384L4.76425 15.9692L0.0148613 10.9692L2.87997 7.95373L0 4.92306L4.70472 0L5.91068 1.23077L2.38214 4.93845L4.03474 6.67691L8.23361 2.25945V0.107727H9.85644V2.43228L13.9504 6.73844L15.5881 5.01537L12.0149 1.24615L13.2209 0.0153855L17.9702 5.01537L15.1179 8.02855ZM12.7951 8.01532L9.02232 11.9845L5.20487 7.96921L8.97768 3.99999L12.7951 8.01532Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_46_58">
          <rect width="18" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ArrowRightIcon: FunctionComponent<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="6"
      height="14"
      viewBox="0 0 6 14"
      fill="none"
      stroke="#131C23"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1 1L4.95286 6.83333L1 12.6667" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const NavBackIcon: FunctionComponent<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#6F8394" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M19.7826 8.58902H3.88373L13.3997 0.378133C13.5519 0.245787 13.4595 0 13.2584 0H10.8536C10.7476 0 10.6471 0.0378131 10.5683 0.105337L0.299618 8.96175C0.205569 9.04279 0.130142 9.14297 0.0784477 9.25551C0.0267536 9.36805 0 9.49032 0 9.61404C0 9.73775 0.0267536 9.86002 0.0784477 9.97256C0.130142 10.0851 0.205569 10.1853 0.299618 10.2663L10.6281 19.1768C10.6688 19.2119 10.7177 19.2308 10.7694 19.2308H13.2557C13.4568 19.2308 13.5491 18.9823 13.397 18.8526L3.88373 10.6417H19.7826C19.9022 10.6417 20 10.5445 20 10.4257V8.8051C20 8.68626 19.9022 8.58902 19.7826 8.58902Z" />
    </svg>
  );
};

export const RightOutlinedIcon: FunctionComponent<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#2775C9" {...props} xmlns="http://www.w3.org/2000/svg">
      <path d="M15.6629 9.43753L5.60045 1.57816C5.57415 1.55746 5.54256 1.54459 5.50928 1.54104C5.476 1.53749 5.4424 1.5434 5.41233 1.55808C5.38226 1.57277 5.35694 1.59564 5.33929 1.62406C5.32163 1.65249 5.31234 1.68532 5.3125 1.71878V3.44423C5.3125 3.55361 5.36384 3.65852 5.44866 3.72548L13.4844 10L5.44866 16.2746C5.36161 16.3416 5.3125 16.4465 5.3125 16.5558V18.2813C5.3125 18.4308 5.48438 18.5134 5.60045 18.4219L15.6629 10.5625C15.7485 10.4958 15.8177 10.4105 15.8652 10.313C15.9128 10.2155 15.9376 10.1085 15.9376 10C15.9376 9.89157 15.9128 9.78453 15.8652 9.68706C15.8177 9.58959 15.7485 9.50425 15.6629 9.43753Z" />
    </svg>
  );
};
